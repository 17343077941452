
import { Component, Vue } from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";
import AdminSteamBotSelector from "@/components/admin/AdminSteamBotSelector.vue";
import gql from "graphql-tag";

@Component({
  components: {
    AdminTable,
    AdminSteamBotSelector
  },
  apollo: {
    partners: {
      query: gql`
        query {
          getAllPartners {
            _id
            name
            avatar
          }
        }
      `,
      fetchPolicy: "no-cache",
      update(data) {
        if (data && data.getAllPartners) {
          const index = this.columns.findIndex(
            ({ field }) => field === "partnerUserIds"
          );
          this.partners = data.getAllPartners;
          this.columns[index].enum = data.getAllPartners;
        }
        return data && data.getAllPartners ? data.getAllPartners : [];
      }
    }
  }
})
export default class AdminSteamBotItem extends Vue {
  get searchCondition() {
    const condition: any = {};
    if (this.selectedSteamBot) {
      condition.steamBotId = this.selectedSteamBot._id;
    }

    if (this.selectedState) {
      condition.state = this.selectedState;
    }

    return Object.keys(condition).length ? condition : null;
  }

  async updateModelDoc(_id: string, updatedFields: any, unsetFields?: any) {
    return this.$apollo.mutate({
      mutation: gql`
        mutation updateModelDoc(
          $_id: ID!
          $updatedFields: JSON!
          $unsetFields: JSON
          $modelName: String!
        ) {
          updateModelDoc(
            _id: $_id
            updatedFields: $updatedFields
            modelName: $modelName
            unsetFields: $unsetFields
          )
        }
      `,
      // Parameters
      variables: {
        _id,
        updatedFields,
        unsetFields,
        modelName: "steamBotItemModel"
      }
    });
  }

  async setPartners() {
    this.setPartnersLoading = true;
    for await (const item of this.selectedItems) {
      try {
        await this.updateModelDoc(item._id, {
          partnerUserIds: this.selectedPartners
        });
        item.partnerUserIds = this.selectedPartners;
      } catch (e) {
        this.$q.notify({
          type: `negative`,
          message: `${item._id}: ${e.message}`
        });
      }
    }
    this.setPartnersLoading = false;
    this.selectedPartners = [];
  }

  partners: any = [];
  selectedSteamBot: any = null;
  selectedState: string | null = null;
  selectedItems: any = [];
  setPartnersLoading = false;
  selectedPartners: any = [];
  columns = [
    {
      name: "_id",
      required: true,
      label: "name",
      field: "_id",
      align: "left",
      type: "string"
    },
    {
      name: "steamBotId",
      required: true,
      label: "steamBotId",
      field: "steamBotId",
      align: "left",
      type: "string",
      editable: true
    },
    {
      name: "steamItemId",
      align: "center",
      label: "steamItemId",
      field: "steamItemId",
      editable: true,
      type: "string"
    },
    {
      name: "state",
      align: "center",
      label: "state",
      field: "state",
      editable: true,
      type: "string",
      enum: ["DEFAULT", "RESERVED", "WITHDRAWN"]
    },
    {
      name: "appId",
      align: "center",
      label: "appId",
      field: "appId",
      type: "number"
    },
    {
      name: "float",
      align: "center",
      label: "float",
      field: "float",
      type: "number"
    },
    {
      name: "tradableAfter",
      align: "center",
      label: "tradableAfter",
      field: "tradableAfter",
      editable: true,
      type: "string"
    },
    {
      name: "partnerUserIds",
      align: "center",
      label: "partnerUserIds",
      field: "partnerUserIds",
      editable: true,
      optionLabel: "name",
      optionValue: `_id`,
      type: "array",
      enum: []
    },
    {
      name: "assetId",
      align: "center",
      label: "assetId",
      field: "assetId",
      editable: true,
      type: "string"
    }
  ];
}
